import React from 'react'
import styled from 'styled-components'

// Import Page Level Components
import InputSelectTemplate from '../../../InputComponents/InputSelectTemplate.js'
import InputDropdownQuotes from '../../../InputComponents/InputDropdownQuotes.js'
import TableHeaderQuotes from '../../Misc/TableHeaderQuotes.js'
import AccordionComponentQuotes from '../../../AccordionComponents/AccordionComponentQuotes.js'

// Import Font Awesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faShareSquare } from '@fortawesome/free-solid-svg-icons'

library.add(faShareSquare)


const GContentQuotesActive = styled.div`
    // 📰
    display: grid;
    grid-template-rows: minmax(0.2fr, 5fr) 0.5fr 0.5fr 5fr 0.2fr;
    grid-template-columns: 1fr;
    grid-template-areas:
    "g_filters"
    "g_table_header"
    "g_shipment_table"
    "g_shipment_pagination";
`;


const GFilters = styled.div`
    grid-area: g_filters; // 🗺
    margin-top: 15px;
    margin-bottom: 15px;
`;

const ExportResults = styled.div`
    grid-area: sg_filter_3; // 🗺
    align-self: end;
    justify-self: end;
    text-align: right;
    color: #666;
    font-weight: 600;
    font-size: 18px;
    a {
      color: #0033a1 !important;
      text-decoration: none;
      cursor: pointer;
      font-size: 14px;
      font-weight: 300;
    }
`;

const SgFilters = styled.div`
    margin: 0 auto;
    max-width: 1360px;
    // 📰
    display: grid;
    grid-template-rows: minmax(1fr, 1fr) 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
    "sg_filter_1 sg_filter_2 sg_filter_3";

    .InputSelectTemplate {
      grid-area: sg_filter_1; // 🗺
    }
    .InputDropdownTemplate {
      grid-area: sg_filter_2; // 🗺
      justify-self: start;
    }
`;


const GTableHeader = styled.div`
    grid-area: g_table_header; // 🗺
    margin-bottom: 5px;
`
;

const GQuotesTable = styled.div`
    grid-area: g_shipment_table; // 🗺
`;

const SgQuotesTable = styled.div`
    margin: 0 auto;
    max-width: 1360px;
    display: grid;
    grid-template-rows: repeat(10, 0.5fr);
    grid-template-columns: 1fr;
    .AccordionComponentQuotes {
      grid-column-start: 1;
      grid-column-end: 10; // 🗺
    }
`;


const GShipmentPagination = styled.div`
    grid-area: g_shipment_pagination; // 🗺
`;

const SgShipmentPagination = styled.div`

    // 📰
    display: grid;
    margin: 0 auto;
    max-width: 1360px;
    margin-top: 40px;
    margin-bottom: 40px;
    grid-template-rows: minmax(0.2fr, 1fr) 1fr 0.2fr;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
    ". sg_item_per_page sg_pagination"
    ". sg_item_per_page sg_page_counter";

    .sg-items-per-page {
      grid-area: sg_item_per_page; // 🗺
      justify-self: center;
      // 🎨
      font-size: 12px;
      color:#768287;
    }
    .sg-pagination {
      grid-area: sg_pagination; // 🗺
      justify-self: end;
      .csg-page {
        font-size: 14px;
        color: #0033a1;
        padding-left: 5px;
        padding-right: 5px;
        border: solid 1px #fff;
        background-color: transparent;
        border-radius: 3px;
        &:hover {
          color: #fff;
          border: solid 1px #fff;
          background-color: #26c1fb;
        }
      }
      .csg-page.active {
        color: #0033a1;
        border: solid 1px #0033a1;
        background-color: transparent;
      }
    }
    .sg-page-counter {
      grid-area: sg_page_counter; // 🗺
      justify-self: end;
      // 🎨
      font-size: 12px;
      color:#768287;
    }
`;


const EchoShipContentGrid = () => {
  return (

    <GContentQuotesActive>

        <GFilters>
            <SgFilters>
                <InputSelectTemplate 
                  labelTitle="Search for Active Quotes"
                  placeholder="Search by Quote, Carrier Name, Zip"
                />
                <InputDropdownQuotes 
                  labelTitle="Sort Active Quotes By"
                />
                <ExportResults>
                15 Quotes <br />
                <a href="/">Export Results</a> <FontAwesomeIcon icon="share-square"  size="xs" style={{ color: '#0033a1' }} />
                </ExportResults>
            </SgFilters>
        </GFilters>

        <GTableHeader>
            <TableHeaderQuotes></TableHeaderQuotes>
        </GTableHeader>
        <GQuotesTable>
            <SgQuotesTable>

                <AccordionComponentQuotes 
                  shipmentNumber="2316041"
                  shipmentStatus="Active"
                  originCity="Snohomish, WA"
                  originZip="98290"
                  destinationCity="Beverly Hills, CA"
                  destinationZip="90210"
                  pickupDate="Thu, Mar 28"
                  carrier="FedEx Economy"
                  transitTime="3 Days"
                  estPrice="$292.91"
                />
                <AccordionComponentQuotes 
                  shipmentNumber="2315848"
                  shipmentStatus="Active"
                  originCity="Chicago, IL"
                  originZip="60654"
                  destinationCity="Beverly Hills, CA"
                  destinationZip="90210"
                  pickupDate="Tue, Mar 26"
                  carrier="CLEAR LANE FRE"
                  transitTime="4 Days"
                  estPrice="$273.90"
                />
                <AccordionComponentQuotes 
                  shipmentNumber="2315847"
                  shipmentStatus="Active"
                  originCity="Chicago, IL"
                  originZip="60654"
                  destinationCity="Beverly Hills, CA"
                  destinationZip="90210"
                  pickupDate="Tue, Mar 26"
                  carrier="FRONTLINE CAR"
                  transitTime="4 Days"
                  estPrice="$227.24"
                />
                <AccordionComponentQuotes 
                  shipmentNumber="2315846"
                  shipmentStatus="Active"
                  originCity="Chicago, IL"
                  originZip="60606"
                  destinationCity="Beverly Hills, CA"
                  destinationZip="90210"
                  pickupDate="Tue, Mar 26"
                  carrier="Central Transport"
                  transitTime="5 Days"
                  estPrice="$311.74"
                />
                <AccordionComponentQuotes 
                  shipmentNumber="2315839"
                  shipmentStatus="Active"
                  originCity="Chicago, IL"
                  originZip="60606"
                  destinationCity="Beverly Hills, CA"
                  destinationZip="90210"
                  pickupDate="Mon, Mar 25"
                  carrier="Saia Motor Freight"
                  transitTime="4 Days"
                  estPrice="$211.05"
                />
                <AccordionComponentQuotes 
                  shipmentNumber="2315789"
                  shipmentStatus="Active"
                  originCity="Chicago, IL"
                  originZip="60606"
                  destinationCity="Beverly Hills, CA"
                  destinationZip="90210"
                  pickupDate="Mon, Mar 25"
                  carrier="The Custom Company"
                  transitTime="3 Days"
                  estPrice="$191.65"
                />
                <AccordionComponentQuotes 
                  shipmentNumber="2315783"
                  shipmentStatus="Active"
                  originCity="Vancouver, BC"
                  originZip="V6C 3L5"
                  destinationCity="Chicago, IL"
                  destinationZip="60639"
                  pickupDate="Fri, Mar 22"
                  carrier="Central Transport"
                  transitTime="6 Days"
                  estPrice="$191.27"
                />
                <AccordionComponentQuotes 
                  shipmentNumber="2402245"
                  shipmentStatus="Active"
                  originCity="Fresno, CA"
                  originZip="93722"
                  destinationCity="Skokie, IL"
                  destinationZip="60077"
                  pickupDate="Fri, Mar 22"
                  carrier="Central Freight Line"
                  transitTime="5 Days"
                  estPrice="$293.93"
                />


            </SgQuotesTable>
        </GQuotesTable>

        <GShipmentPagination>
            <SgShipmentPagination>

                <div className="sg-items-per-page">
                  <div className="es-c-items-per-page">
                    <div className="es-cg-items-per-page">
                      Items per page: <a href="#">10</a> |  <a href="#">25</a> | <a href="#">50</a>
                    </div>
                  </div>
                </div>

                <div className="sg-pagination">
                  <a className="csg-page active">1</a>
                  <a className="csg-page">2</a>
                  <a className="csg-page">3</a>
                  <a className="csg-page">4</a>
                  <a className="csg-page">5</a>
                  <a className="csg-page">&gt;</a>
                  <a className="csg-page">&gt;&gt;</a>
                </div>

                <div className="sg-page-counter">
                  Page 1 of 310
                </div>

            </SgShipmentPagination>
        </GShipmentPagination>


    </GContentQuotesActive>

  )
}

export default EchoShipContentGrid;